<template>
  <Page
    :showButton=false
    :title="'Configuration'"
    class="c-configuration"
  >
    <template #content>
      <el-tabs v-model="state.activeName">
        <el-tab-pane label="City" name="first">
          <Page
            @cta:click="onClick"
            :title="'Cities'"
            class="c-users"
          >
            <template #content>
              <el-table
                :data="cities.filter(data => !state.search || data.name.toLowerCase().includes(state.search.toLowerCase()))"
                style="width: 100%"
              >
                <el-table-column
                  label="ID"
                  prop="id"
                  width="100"
                >
                </el-table-column>
                <el-table-column
                  label="Name"
                  prop="name">
                </el-table-column>

                <el-table-column
                  align="right">
                  <template #header>
                    <el-input
                      v-model="state.search"
                      size="mini"
                      placeholder="Type to search"/>
                  </template>
                  <template #default="scope">
                    <el-button
                      @click="editConfiguration(scope.row)"
                      size="mini">Edit
                    </el-button>
                    <el-button
                      @click="onDelete(scope.row)"
                      size="mini"
                      type="danger">Delete
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>

              <div class="c-configuration__pagination">
                <el-pagination
                  @next-click="onNextPage"
                  @prev-click="onPrevPage"
                  @current-change="onPageClick"
                  :total="cities && cities.length"
                  :page-size="state.citySize"
                  :hide-on-single-page="false"
                  background
                  layout="prev, pager, next"
                >
                </el-pagination>
              </div>
            </template>
          </Page>
        </el-tab-pane>
        <el-tab-pane label="Cast" name="second">
          <Page
            @cta:click="onClick"
            :title="'casts'"
            class="c-users"
          >
            <template #content>
              <el-table
                :data="getCastData"
                style="width: 100%"
              >
                <el-table-column
                  label="ID"
                  prop="id"
                  width="100"
                >
                </el-table-column>
                <el-table-column
                  label="Name"
                  prop="name">
                </el-table-column>

                <el-table-column
                  align="right">
                  <template #default="scope">
                    <el-button
                      @click="editConfiguration(scope.row)"
                      size="mini">Edit
                    </el-button>
                    <el-button
                      @click="onDelete(scope.row)"
                      size="mini"
                      type="danger">Delete
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </Page>
        </el-tab-pane>
        <el-tab-pane label="Religion" name="third">
          <Page
            @cta:click="onClick"
            :title="'Religion'"
            class="c-religion"
          >
            <template #content>
              <el-table
                :data="getReligionData"
                style="width: 100%"
              >
                <el-table-column
                  label="ID"
                  prop="id"
                  width="100"
                >
                </el-table-column>
                <el-table-column
                  label="Name"
                  prop="name">
                </el-table-column>

                <el-table-column
                  align="right">
                  <template #default="scope">
                    <el-button
                      @click="editConfiguration(scope.row)"
                      size="mini">Edit
                    </el-button>
                    <el-button
                      @click="onDelete(scope.row)"
                      size="mini"
                      type="danger">Delete
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </Page>
        </el-tab-pane>
        <el-tab-pane label="Educations" name="fourth">
          <Page
            @cta:click="onClick"
            :title="'Educations'"
            class="c-educations"
          >
            <template #content>
              <el-table
                :data="getEducationData"
                style="width: 100%"
              >
                <el-table-column
                  label="ID"
                  prop="id"
                  width="100"
                >
                </el-table-column>
                <el-table-column
                  label="Name"
                  prop="name">
                </el-table-column>

                <el-table-column
                  align="right">
                  <template #default="scope">
                    <el-button
                      @click="editConfiguration(scope.row)"
                      size="mini">Edit
                    </el-button>
                    <el-button
                      @click="onDelete(scope.row)"
                      size="mini"
                      type="danger">Delete
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </Page>
        </el-tab-pane>
        <el-tab-pane label="Occupations" name="fifth">
          <Page
            @cta:click="onClick"
            :title="'Occupations'"
            class="c-occupations"
          >
            <template #content>
              <el-table
                :data="getOccupationData"
                style="width: 100%"
              >
                <el-table-column
                  label="ID"
                  prop="id"
                  width="100"
                >
                </el-table-column>
                <el-table-column
                  label="Name"
                  prop="name">
                </el-table-column>

                <el-table-column
                  align="right">
                  <template #default="scope">
                    <el-button
                      @click="editConfiguration(scope.row)"
                      size="mini">Edit
                    </el-button>
                    <el-button
                      @click="onDelete(scope.row)"
                      size="mini"
                      type="danger">Delete
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </Page>
        </el-tab-pane>
      </el-tabs>
      <Popup
        @action:confirm="onSubmit"
        @popup:close="hideAddPopup"
        :title="modalTitle"
        :showPopup="state.showAddPopup"
        :loading="state.loading"
      >
        <template #content>
          <el-form>
            <el-form-item label="Name">
              <el-input
                v-model="state.data.name"
              ></el-input>
              <span
                class="c-configuration__error"
                v-if="v$.data.name.$error"
              >
                {{ v$.data.name.$errors[0].$message }}
              </span>
            </el-form-item>
          </el-form>
        </template>
      </Popup>
    </template>
  </Page>
</template>

<script>
import {
  required,
  helpers
} from '@vuelidate/validators'
import { computed, reactive } from 'vue'
import useValidate from '@vuelidate/core'

import configurationMixin from '@/mixins/configuration'

import Page from '@/components/Page'
import Popup from '@/components/Popup'

export default {
  components: {
    Popup,
    Page
  },
  created () {
    this.getCities()
    this.getCasts()
    this.getReligions()
    this.getEducations()
    this.getOccupations()
  },

  setup () {
    const state = reactive({
      activeName: 'first',
      showAddPopup: false,
      loading: false,
      cityPageNumber: 1,
      citySize: 10,
      search: '',
      data: {
        id: null,
        name: ''
      }
    })

    const rules = computed(() => {
      return {
        data: {
          name: { required: helpers.withMessage('Name field is required', required) }
        }
      }
    })

    const v$ = useValidate(rules, state)

    return {
      state,
      v$
    }
  },

  computed: {
    paginatedCities () {
      const start = (this.state.cityPageNumber - 1) * this.state.citySize
      const end = start + this.state.citySize
      return this.cities ? this.cities.slice(start, end).map(city => {
        return {
          id: city.id,
          name: city.name
        }
      }) : null
    },

    getCityData () {
      return this.cities && this.cities.map(city => {
        return {
          id: city.id,
          name: city.name
        }
      })
    },

    getEducationData () {
      return this.educations && this.educations.map(education => {
        return {
          id: education.id,
          name: education.name
        }
      })
    },

    getOccupationData () {
      return this.occupations && this.occupations.map(occupation => {
        return {
          id: occupation.id,
          name: occupation.name
        }
      })
    },

    modalTitle () {
      if (this.state.activeName === 'first') {
        return 'Add City Form'
      }

      if (this.state.activeName === 'second') {
        return 'Add Cast Form'
      }

      if (this.state.activeName === 'third') {
        return 'Add Religion Form'
      }

      if (this.state.activeName === 'fourth') {
        return 'Add Education Form'
      }

      if (this.state.activeName === 'fifth') {
        return 'Add Occupation Form'
      }

      return null
    },

    getCastData () {
      return this.casts && this.casts.map(cast => {
        return {
          id: cast.id,
          name: cast.name
        }
      })
    },

    getReligionData () {
      return this.religions && this.religions.map(religion => {
        return {
          id: religion.id,
          name: religion.name
        }
      })
    }
  },
  methods: {
    editConfiguration (data) {
      this.state.showAddPopup = true

      this.state.data = data
    },

    onNextPage () {
      this.state.cityPageNumber++
    },

    onPrevPage () {
      this.state.cityPageNumber--
    },

    onPageClick (pageNumber) {
      this.state.cityPageNumber = pageNumber
    },

    onDelete (data) {
      this.$confirm(`This will permanently delete ${data.name} . Continue?`, 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        if (this.state.activeName === 'first') {
          this.deleteCity(data).then(() => {
            this.getCities()

            this.$message({
              type: 'success',
              message: 'City deleted successfully.'
            })
          })
        }

        if (this.state.activeName === 'second') {
          this.deleteCast(data).then(() => {
            this.getCasts()

            this.$message({
              type: 'success',
              message: 'Cast deleted successfully.'
            })
          })
        }

        if (this.state.activeName === 'third') {
          this.deleteReligion(data).then(() => {
            this.getReligions()

            this.$message({
              type: 'success',
              message: 'Religion deleted successfully.'
            })
          })
        }

        if (this.state.activeName === 'fourth') {
          this.deleteEducation(data).then(() => {
            this.getEducations()

            this.$message({
              type: 'success',
              message: 'Education deleted successfully.'
            })
          })
        }

        if (this.state.activeName === 'fifth') {
          this.deleteOccupation(data).then(() => {
            this.getOccupations()

            this.$message({
              type: 'success',
              message: 'Occupation deleted successfully.'
            })
          })
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: 'Delete canceled'
        })
      })
    },

    onClick () {
      this.state.showAddPopup = true
    },

    hideAddPopup () {
      this.state.showAddPopup = false

      this.state.data = {
        id: null,
        name: ''
      }
    },

    onSubmit () {
      if (this.state.activeName === 'first') {
        if (this.state.data.id) {
          this.v$.$validate()
          if (!this.v$.$error) {
            this.state.loading = true
            this.updateCity(this.state.data).then(() => {
              this.$message({
                message: 'City updated successfully.',
                type: 'success'
              })

              this.getCities()
              this.state.showAddPopup = false
              this.state.loading = false
            })
          }
        } else {
          this.v$.$validate()
          if (!this.v$.$error) {
            this.createCity(this.state.data.name).then(() => {
              this.$message({
                message: 'City created successfully.',
                type: 'success'
              })

              this.getCities()
              this.state.showAddPopup = false
              this.state.loading = false
            })
          }
        }
      }

      if (this.state.activeName === 'second') {
        if (this.state.data.id) {
          this.v$.$validate()
          if (!this.v$.$error) {
            this.loading = true

            this.updateCast(this.state.data).then(() => {
              this.$message({
                message: 'Cast updated successfully.',
                type: 'success'
              })

              this.getCasts()
              this.state.showAddPopup = false
              this.state.loading = false
            })
          }
        } else {
          this.v$.$validate()
          if (!this.v$.$error) {
            this.loading = true

            this.createCast(this.state.data.name).then(() => {
              this.$message({
                message: 'Cast created successfully.',
                type: 'success'
              })

              this.getCasts()
              this.state.showAddPopup = false
              this.state.loading = false
            })
          }
        }
      }

      if (this.state.activeName === 'third') {
        if (this.state.data.id) {
          this.v$.$validate()
          if (!this.v$.$error) {
            this.loading = true

            this.updateReligion(this.state.data).then(() => {
              this.$message({
                message: 'Religion updated successfully.',
                type: 'success'
              })

              this.getReligions()
              this.state.showAddPopup = false
              this.state.loading = false
            })
          }
        } else {
          this.v$.$validate()
          if (!this.v$.$error) {
            this.loading = true
            this.createReligion(this.state.data.name).then(() => {
              this.$message({
                message: 'Religion created successfully.',
                type: 'success'
              })

              this.getReligions()
              this.state.showAddPopup = false
              this.state.loading = false
            })
          }
        }
      }

      if (this.state.activeName === 'fourth') {
        if (this.state.data.id) {
          this.v$.$validate()
          if (!this.v$.$error) {
            this.loading = true

            this.updateEducation(this.state.data).then(() => {
              this.$message({
                message: 'Education updated successfully.',
                type: 'success'
              })

              this.getEducations()
              this.state.showAddPopup = false
              this.state.loading = false
            })
          }
        } else {
          this.v$.$validate()
          if (!this.v$.$error) {
            this.loading = true

            this.createEducation(this.state.data).then(() => {
              this.$message({
                message: 'Education created successfully.',
                type: 'success'
              })

              this.getEducations()
              this.state.showAddPopup = false
              this.state.loading = false
            })
          }
        }
      }

      if (this.state.activeName === 'fifth') {
        if (this.state.data.id) {
          this.v$.$validate()
          if (!this.v$.$error) {
            this.loading = true
            this.updateOccupation(this.state.data).then(() => {
              this.$message({
                message: 'Occupation updated successfully.',
                type: 'success'
              })

              this.getOccupations()
              this.state.showAddPopup = false
              this.state.loading = false
            })
          }
        } else {
          this.v$.$validate()
          if (!this.v$.$error) {
            this.loading = true
            this.createOccupation(this.state.data).then(() => {
              this.$message({
                message: 'Occupation created successfully.',
                type: 'success'
              })

              this.getOccupations()
              this.state.showAddPopup = false
              this.state.loading = false
            })
          }
        }
      }
    }
  },
  mixins: [configurationMixin]
}
</script>

<style lang="scss" scoped>
.c-configuration {
  &__error {
    color: darkred;
  }
}
</style>
